.mb6 {
    margin-bottom: 6px;
}
.mb12 {
    margin-bottom: 12px;
    @include mobile {
        margin-bottom: 9px;
    }
}
.mb18 {
    margin-bottom: 18px;
    @include tablet {
        margin-bottom: 12px;
    }
}
.mb24 {
    margin-bottom: 24px;
    @include tablet {
        margin-bottom: 18px;
    }
}
.mb30 {
    margin-bottom: 30px;
    @include tablet {
        margin-bottom: 21px;
    }
}
.mb36 {
    margin-bottom: 36px;
    @include tablet {
        margin-bottom: 24px;
    }
}
.mb42 {
    margin-bottom: 42px;
    @include tablet {
        margin-bottom: 30px;
    }
}
.mb48 {
    margin-bottom: 48px;
    @include tablet {
        margin-bottom: 30px;
    }
}
.mb60 {
    margin-bottom: 60px;
    @include tablet {
        margin-bottom: 36px;
    }
}
.mb72 {
    margin-bottom: 60px; //72px;
    @include tablet {
        margin-bottom: 48px;
    }
}

.mb120 {
    margin-bottom: 160px; //72px;
    @include tablet {
        margin-bottom: 48px;
    }
}

// left
.lm18 {
    margin-left: 18px;
    @include tablet {
        margin-left: 12px;
    }
}

// top
.mt36 {
    margin-top: 36px;
    @include tablet {
        margin-top: 24px;
    }
}

.mt18 {
    margin-top: 18px;
    @include tablet {
        margin-top: 12px;
    }
}

.mt6 {
    margin-top: 6px;
}

.pt36 {
    padding-top: 36px;
}
