.desktopHide {
    @include desktop {
        display: none !important;
    }
}

.mobileHide {
    @include mobile {
        display: none !important;
    }
}

.tabletDesktopHide {
    @media only screen and (min-width: $maxMobile + 1px) {
        display: none !important;
    }
}

.tabletMobileHide {
    @media only screen and (max-width: $maxTablet) {
        display: none !important;
    }
}
