@import '/styles/vars';

.btn {
    user-select: none;
    transform: translate3d(0, 0, 0);
    cursor: pointer;
    vertical-align: top;
    height: $gap48;
    line-height: $gap48 - 1px;
    padding: 0 $gap36;
    border-radius: $radius3; //24px;
    display: inline-block;
    font-size: $font16;
    white-space: nowrap;
    font-weight: $normal;
    min-width: 180px;
    border: 0;
    text-align: center;
    position: relative;
    // font-family: 'Jost', sans-serif;
    font-family: 'Nunito Sans', sans-serif;
    flex-shrink: 0;
    color: $color_dark;
    transition: background $ease, color $ease;
    background: transparent;
    > * {
        vertical-align: top;
    }
    b {
        font-size: 1.25em;
    }
    svg {
        margin-right: 12px;
        width: 24px;
        height: 24px;
        position: relative;
        top: 12px;
    }
    &:hover {
        text-decoration: none;
    }
}

.small {
    height: $gap42;
    line-height: $gap42;
    min-width: 0;
}

.block {
    display: block;
    width: 100%;
    flex-shrink: 1;
    + .block {
        margin-top: $gap12;
    }
}

// styles

.yellow {
    background: $color_accent;
    &:hover {
        background: $hover_color_accent;
    }
}

.red {
    color: #fff;
    background: $color_red;
    &:hover {
        background: $hover_color_red;
    }
}

.ghost {
    background: transparent;
    border: 1px solid $color_border;
    transition: border $ease;
    line-height: $gap48 - 2px;
    &:hover {
        background: transparent;
        border-color: fade-out($color_medium, 0.6);
    }
}

.green {
    background: $color_green;
    color: #fff;
    &:hover {
        background: $hover_color_green;
    }
    &.ghost {
        background: transparent;
        border-color: $color_green;
        color: $color_green;
        border-color: fade-out($color_green, 0.5);
        &:hover {
            border-color: $color_green;
        }
    }
}

.blank {
    padding: 0 !important;
    min-width: 0 !important;
    width: auto !important;
    flex-grow: 0 !important;
    font-weight: $normal;
    background: transparent;
    &:hover {
        text-decoration: underline;
    }
}

.link {
    text-decoration: uppercase;
    letter-spacing: 0.01em;
    border-bottom: 1px solid $color_green_dark;
    border-radius: 0;
    padding: 0;
    transition: border $ease;
    &:hover {
        border-bottom-color: transparent;
    }
}

.big {
    height: 60px;
}

.google {
    background: #d24c38;
    color: #fff;
    transition: opacity $ease;
    &:hover {
        opacity: 0.9;
    }
}

.facebook {
    background: #3e5993;
    color: #fff;
    transition: opacity $ease;
    &:hover {
        opacity: 0.9;
    }
}

// .facebook,
// .google,
.light {
    background: $color_light;
    &:hover {
        background: $hover_color_light;
    }
}

.light {
    color: $color_green_dark;
    font-weight: $normal;
}

.white {
    background: #fff;
    transition: opacity $ease;
    &:hover {
        opacity: 0.9;
    }
}

@include mobile {
    .btn {
        font-size: $fontm;
        //line-height: $gap42;
        //height: $gap42;
        min-width: 120px;
        //padding: 0 $gap24;
        //svg {
        //    top: 9px;
        //}
    }
    .ghost {
        line-height: $gap42 - 2px;
    }
}
