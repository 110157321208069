@import '/styles/vars';

.caret {
    position: absolute;
    right: $gap18;
    pointer-events: none;
    width: 18px;
    height: 18px;
    bottom: 50%;
    margin-bottom: -9px;
    @include mobile {
        bottom: 12px;
    }
}

.input {
    margin: 0 0 $gap30 0;
    > div {
        position: relative;
    }
    input[type='search']::-webkit-search-decoration,
    input[type='search']::-webkit-search-cancel-button,
    input[type='search']::-webkit-search-results-button,
    input[type='search']::-webkit-search-results-decoration {
        -webkit-appearance: none;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
    }
    input,
    textarea,
    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        -o-appearance: none;
        appearance: none;
        height: 60px;
        border-radius: $radius2;
        border: 0;
        width: 100%;
        padding: 21px $gap18 0 $gap18;
        color: $color_dark;
        // font-weight: $medium;
        font-size: $font16;
        background: $color_light;
        transition: border-color $ease, box-shadow $ease;
        box-shadow: inset 0 0 0px 1px transparent;
        &::placeholder {
            color: $color_medium;
            font-weight: $normal;
            transition: color $ease;
        }
        &:focus {
            // border-color: $color_accent;
            // box-shadow: inset 0 0 0px 1px $color_accent;
            &::placeholder {
                color: transparent;
            }
        }
    }
    select {
        padding-right: 48px;
        &[readonly] {
            user-select: none;
            pointer-events: none;
        }
    }
    label {
        text-align: left;
        position: absolute;
        margin: 0 9px;
        left: 0;
        top: 14px;
        line-height: 1.4;
        transform: translate3d(0, 0, 0);
        pointer-events: none;
        right: 0;
        > div {
            float: left;
            max-width: 100%;
            display: flex;
            // background: $color_bg;
            padding: 0 9px;
            white-space: nowrap;
            > div {
                padding: 5px 0;
                display: inline-block;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 100%;
            }
        }
    }
    textarea {
        padding-top: 29px;
        padding-bottom: 15px;
        resize: none;
        display: block;
        min-height: $gap48 * 3;
        line-height: 1.5;
    }
    [readonly] {
        opacity: 0.3;
        box-shadow: none !important;
        + label + svg {
            opacity: 0.3;
        }
    }
    [readonly] + label {
        color: $color_faded;
    }
    [type='file'] {
        text-indent: -6000px;
    }
    &.hasValue {
        label {
            font-size: $font13;
            top: 3px;
            color: $color_faded;
        }
    }
    &.isFocus label,
    input:focus:not([readonly]) + label,
    select:focus:not([readonly]) + label,
    textarea:focus:not([readonly]) + label {
        font-size: $font13;
        top: 3px;
        color: $color_faded;
        -webkit-font-smoothing: initial;
        -moz-osx-font-smoothing: initial;
    }
    @include mobile {
        margin: 0 0 $gap24 0;
        /*
        input,
        textarea,
        select {
            height: $gap42;
            //font-size: $fontm;
            line-height: initial;
        }
        textarea {
            min-height: $gap42 * 3;
        }
        label {
            top: 5px;
        }
        */
    }
    &.simple {
        input,
        textarea,
        select {
            height: 48px;
            padding-top: 0;
        }
    }
}

.reactSelect {
    input {
        border: 0 !important;
        box-shadow: none !important;
        height: 34px;
    }
    //@include mobile {
    //    input {
    //        height: 28px !important;
    //    }
    //}
}

.fileName {
    text-align: left;
    position: absolute;
    line-height: $gap48;
    top: 17px;
    padding: 0 18px;
    left: 0;
    color: $color_dark;
    pointer-events: none;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    //@include mobile {
    //    line-height: $gap42;
    //}
}

.icon {
    position: absolute !important;
    pointer-events: none;
    right: 0;
    top: 0;
}

.iconActive {
    pointer-events: all;
    z-index: 2;
    &:hover {
        color: $color_red;
    }
}
