@import '/styles/vars';

.image {
    transition: opacity $ease;
}

.loading {
    transform: translate3d(0, 0, 0);
    opacity: 0;
}
