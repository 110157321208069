@import '/styles/vars';

.wrap {
    max-width: $wrap;
    padding: 0 $gap36;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    @include mobile {
        padding: 0 $gapM;
    }
}
