@import '/styles/vars';

.bubble {
    position: absolute;
    padding: 9px $gap12;
    top: 0;
    left: 0;
    transform: translate(-50%, -100%);
    margin-top: -9px;
    border-radius: $radius1;
    background: $color_dark;
    color: #fff;
    font-size: $font13;
    opacity: 0.96;
    z-index: 1000;
    max-width: 240px;
}

.left {
    transform: translate(0, -100%);
}
