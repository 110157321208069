@import '/styles/vars';

html {
    .newsletter {
        background: $color_light;
        overflow: hidden;
        position: relative;
        border-radius: $radius3;
        pointer-events: all;
        color: $color_dark;
        max-width: 1280px;
        margin: 0 auto;
    }

    .input {
        input {
            background: #fff;
        }
        label {
            color: $color_dark !important;
            > div {
                background: $color_yellow;
            }
        }
    }

    .group {
        display: flex;
        width: 540px;
        margin-bottom: -24px;
        > *:first-child {
            width: 66%;
        }
        > *:last-child {
            width: 33%;
            margin-left: $gap24;
        }
        @include mobile {
            display: block;
            width: 100%;
            margin: 0;
            > *:first-child {
                margin: 0 0 $gap12 0;
                width: 100%;
            }
            > *:last-child {
                margin: 0;
                width: 100%;
            }
        }
    }

    .image {
        height: auto;
        width: auto;
        height: 340px;
        > div {
            height: 100% !important;
            > div {
                height: 100% !important;
            }
        }
        @include mobile {
            display: none;
        }
    }

    .content {
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        height: 100%;
        padding: $gap24 $gap12;
        p {
            margin: 12px 0 $gap48 0;
        }
        @include mobile {
            position: relative;
            p {
                margin: 0 0 $gap24 0;
            }
        }
    }

    .text {
        @media (max-width: 420px) {
            padding: 0 0 0 60px;
            text-align: left;
            h2 {
                font-size: 18px;
            }
            br {
                display: none;
            }
            p {
                font-size: 13px;
                line-height: 1.3;
            }
        }
    }

    .mobileImage {
        display: none;
        @include mobile {
            display: block;
            position: absolute;
            width: 90px;
            top: 12px;
            left: -24px;
        }
    }
}
