@import '/styles/vars';

html {
    .form {
        position: relative;
        display: flex;
        width: 100%;
        @media (max-width: 420px) {
            flex-wrap: wrap;
            > * {
                margin: 12px 0 0 0 !important;
                width: 100%;
            }
        }
    }

    // .button {
    //     position: absolute;
    //     right: 0;
    //     bottom: 0;
    //     background: transparent !important;
    //     &:hover {
    //         color: $color_accent;
    //     }
    // }

    .submitText {
        position: absolute;
        right: 0;
        top: 0;
        height: 48px;
        line-height: 48px;
        padding: 0 18px;
        cursor: pointer;
        border: 0;
        background: transparent;
        color: $color_red;
        font-weight: 500;
        font-size: $font16;
    }

    .input {
        width: 100%;
        margin: 0 12px 0 0;
        input {
            background: #fff;
            border: 0;
            box-shadow: none !important;
            outline: 0 !important;
            border: 0 !important;
        }
    }
}
