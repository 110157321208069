.ais-Hits-list {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0 (-$gap12);
    @include mobile {
        margin: 0 -9px; //
    }
    article {
        list-style: none;
        width: 25%;
        margin: 0 0 $gap48 0;
        @include middle {
            width: 33.33%;
        }
        @include tablet {
            width: 50%;
        }
        @include mobile {
            width: 33.33%;
        }
        @media (max-width: 550px) {
            width: 50%;
        }
    }
}

.ais-Hits-list-full {
    article {
        width: 20%;
        @include middle {
            width: 25%;
            &:nth-child(9),
            &:nth-child(10) {
                display: none;
            }
        }
        @include tablet {
            width: 33.33%;
        }
        @include mobile {
            width: 50%;
            &:nth-child(9),
            &:nth-child(10) {
                display: block;
            }
        }
        @media (max-width: 550px) {
            width: 50%;
        }
    }
}

/*
.ais-Hits-item {
    list-style: none;
    width: 25%;
    margin: 0 0 $gap48 0;
    @include middle {
        width: 33.33%;
    }
    @include tablet {
        width: 50%;
    }
    @include mobile {
        width: 33.33%;
    }
    @media (max-width: 550px) {
        width: 50%;
    }
}
*/

.ais-CurrentRefinements-group {
    display: flex;
    flex-wrap: wrap;
}

.ais-Stats {
    @include mobile {
        order: 2;
        margin-left: 15px;
    }
}

.ais-SortBy {
    position: relative;
    select {
        padding-right: 15px;
    }
    &:after {
        content: '';
        position: absolute;
        pointer-events: none;
        width: 6px;
        height: 6px;
        margin-top: -5px;
        border-bottom: 1px solid $color_medium;
        border-right: 1px solid $color_medium;
        transform: rotate(45deg);
        top: 50%;
        right: 0;
    }
}

.ais-Meta {
    border-bottom: $dashed;
    display: flex;
    justify-content: space-between;
    height: $gap36;
    align-items: center;
    font-size: $font16;
    font-weight: $medium;
    color: $color_dark;
    select {
        font-weight: $medium;
        font-size: $font16;
        direction: rtl;
        appearance: none;
        background: transparent;
        border: 0;
        height: 24px;
        color: $color_dark;
    }
    @include mobile {
        justify-content: flex-end;
        &.ais-Meta-Account {
            justify-content: start;
            margin-bottom: 36px;
        }
    }
}

.ais-CurrentRefinements--noRefinement {
    display: none;
}

.ais-CurrentRefinements-list {
    margin: 0 0 (-$gap12) 0;
    padding: 0;
}

.ais-CurrentRefinements-query {
    margin: 0;
}

.ais-CurrentRefinements-item {
    display: inline-block;
    list-style: none;
    padding: 6px 0 6px $gap18;
    background: $color_light;
    border-radius: $radius2;
    font-size: $font14;
    line-height: 24px;
    margin-right: $gap12;
    margin-bottom: $gap12;
}

.ais-CurrentRefinements-delete {
    border: 0;
    cursor: pointer;
    color: $color_medium;
    margin-left: 6px;
    margin-right: 6px;
    background: transparent;
    transition: color $ease;
    &:hover {
        color: $color_accent;
    }
}

.ais-ClearRefinements-button--disabled {
    display: none;
}

.ais-Heading {
    font-weight: $bold;
    color: $color_dark;
    border-bottom: $dashed;
    line-height: $gap36;
}

.ais-RefinementList-list,
.ais-HierarchicalMenu-list {
    margin: 0;
    padding: 0;
    font-size: $font14;
    ul {
        padding-left: $gap24;
    }
}

.ais-ToggleRefinement {
    font-size: $font14;
    label {
        display: block;
        line-height: $gap18;
        padding: 3px 0;
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
    input:checked + span {
        color: $color_green;
    }
}

.ais-RefinementList-item,
.ais-HierarchicalMenu-item {
    line-height: $gap18;
    list-style: none;
    .ais-RefinementList-label,
    a {
        display: flex;
        padding: 3px 0;
        justify-content: space-between;
        cursor: pointer;
        &:hover {
            text-decoration: none;
            .ais-RefinementList-labelText,
            .ais-HierarchicalMenu-label {
                text-decoration: underline;
            }
        }
    }
}

.ais-RefinementList-count,
.ais-HierarchicalMenu-count {
    color: $color_faded;
    margin-left: $gap12;
    display: none !important;
}

.ais-RefinementList-item--selected > *,
.ais-HierarchicalMenu-item--selected > a {
    color: $color_green;
}

.ais-SearchBox {
    position: relative;
    margin: 0 0 $gap12 0;
    input[type='search']::-webkit-search-decoration,
    input[type='search']::-webkit-search-cancel-button,
    input[type='search']::-webkit-search-results-button,
    input[type='search']::-webkit-search-results-decoration {
        -webkit-appearance: none;
    }
    input {
        width: 100%;
        border: 0;
        background: transparent;
        border-radius: $radius1;
        border: 1px solid $color_border;
        height: $gap36;
        padding: 0 $gap12;
        color: inherit;
    }
    .ais-SearchBox-reset {
        position: absolute;
        right: 0;
        top: 0;
        border: 0;
        width: 36px;
        height: 36px;
        background: transparent;
        cursor: pointer;
        svg {
            fill: $color_faded;
        }
    }
}

.ais-RefinementList-noResults {
    font-size: $font14;
}

.ais-RefinementList-searchBox,
.ais-SearchBox-submit,
.ais-ToggleRefinement-checkbox,
.ais-RefinementList-checkbox {
    display: none;
}

.ais-RefinementList-showMore {
    appearance: none;
    border: 0;
    background: transparent;
    padding: 0;
    cursor: pointer;
    color: $color_faded;
    &:hover {
        text-decoration: underline;
    }
}

.ais-Pagination {
    ul {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
    }
    li {
        list-style: none;
        max-width: 12.5%;
    }
    .ais-Pagination-link {
        max-width: 100%;
        width: $gap48;
        height: $gap48;
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        &:hover {
            color: $color_green;
            text-decoration: none;
            // background: $color_light;
            // border-radius: $radius2;
        }
    }
    .ais-Pagination-list--noRefinement {
        display: none;
    }
}

.ais-Pagination-link--selected {
    color: $color_green;
    font-weight: $bold;
    background: $color_light;
    border-radius: $radius2;
}

.ais-RangeInput-form {
    display: flex;
    align-items: center;
}

.ais-RangeInput-input {
    border: 0;
    background: transparent;
    height: 36px;
    width: 54px;
    appearance: none;
    border: 1px solid $color_border;
    border-radius: $radius1;
    -moz-appearance: textfield;
    padding: 0 6px;
    color: inherit;
    text-align: center;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.ais-RangeInput-submit {
    background: transparent;
    display: inline-block;
    padding: 0;
    border: 0;
    margin-left: 9px;
    cursor: pointer;
    margin: 0 0 0 auto;
    font-family: inherit;
    height: 36px;
    border-radius: $radius1;
    color: inherit;
    padding: 0 12px;
    font-size: 13px;
    background: $color_light;
    transition: background $ease;
    &:hover {
        background: $hover_color_light;
    }
}

.ais-RangeInput-separator {
    margin: 0 6px;
    font-size: 13px;
}
