@import '/styles/vars';

.heading {
    color: $color_dark;
    font-weight: $boldHeading;
    margin-top: 0;
    font-family: $headerFont;
    a {
        &:hover {
            text-decoration: underline;
            -webkit-text-decoration-color: $color_accent;
            text-decoration-color: $color_accent;
        }
    }
}

.center {
    text-align: center;
}

.heading48 {
    font-size: $font48;
    line-height: 1.125;
    @include tablet {
        font-size: $font36;
        line-height: 1.2;
    }
    @include mobile {
        font-size: $font24;
        line-height: 1.2;
    }
}

.heading36 {
    font-size: $font36;
    line-height: 1.17;
    @include mobile {
        font-size: 24px;
        line-height: 1.15;
    }
}

.heading30 {
    font-size: $font30;
    line-height: 1.33;
    @include mobile {
        font-size: 21px;
        line-height: 1.3;
    }
}

.heading24 {
    font-size: $font24;
    line-height: 1.375;
    @include mobile {
        font-size: $font18;
        line-height: 1.333;
    }
}

.heading18 {
    font-size: $font18;
    line-height: 1.333;
    @include mobile {
        font-size: $font16;
    }
}

.heading16 {
    font-size: $font14;
    line-height: 1.3125;
    font-weight: $medium;
    a {
        &:hover {
            -webkit-text-decoration-color: initial;
            text-decoration-color: initial;
        }
    }
    @include mobile {
        font-size: $font13;
    }
}

.body {
    font-family: $bodyFont;
    // font-weight: 400;
}
