@import '/styles/vars';

.info {
    position: relative;
    top: -0.1em;
    margin: 0 0 0 6px;
    pointer-events: all;
    svg {
        fill: none;
        pointer-events: none;
        stroke-width: 2.4;
        width: 15px;
        height: 15px;
    }
}
