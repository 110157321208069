@import '/styles/vars';

.footer {
    margin: auto 0 0 0;
    z-index: 2;
    a {
        &:hover {
            text-decoration: underline;
        }
    }
}

.footerWrap {
    margin-top: 4.484vw;
    padding-top: 48px;
    position: relative;
}

.footerContent {
    position: relative;
}

.footerBg {
    position: absolute;
    width: 100vw;
    width: var(--vw100);
    top: 0;
    left: 50%;
    margin-left: -50vw;
    height: 100%;
    background: $color_yellow;
    svg {
        fill: $color_yellow;
        width: 100%;
        position: absolute;
        bottom: 100%;
        height: 4.484vw;
        margin-bottom: -1px;
    }
}

.cols {
    display: flex;
    justify-content: space-between;
    font-size: $font14;
    line-height: 30px;
    @include middle {
        flex-wrap: wrap;
    }
    @include mobile {
        font-size: $font13;
        padding-bottom: 0;
    }
}

.col {
    display: flex;
    flex-direction: column;
    // a {
    //     margin-bottom: 9px;
    // }
    h4 {
        font-weight: $medium;
    }
    @include mobile {
        width: 47%;
        margin: 0 0 $gap48 0;
        &:first-child {
            order: 10;
            width: 100%;
        }
    }
}

.requisites {
    padding: 0 0 $gap30 0;
    font-size: $font13;
    display: flex;
    line-height: 2;
    justify-content: flex-end;
    a {
        white-space: nowrap;
        margin-left: $gap24;
    }
    > div {
        margin: 0 auto 0 0;
    }
    @include middle {
        font-size: $font13;
        line-height: 21px;
        flex-wrap: wrap;
        justify-content: flex-start;
        > div {
            order: 20;
            width: 100%;
            padding-right: 48px;
        }
        a {
            display: inline-block;
            margin: 0 $gap18 6px 0;
        }
    }
}

.newsletter {
    text-align: center;
    margin-bottom: 90px;
    p {
        opacity: 0.5;
    }
    @include mobile {
        p {
            font-size: $font13;
        }
    }
}

.input {
    max-width: 540px;
    margin: 0 auto;
}

.contactBtn {
    position: fixed;
    z-index: 16;
    width: 60px;
    height: 60px;
    right: 16px;
    bottom: 16px;
    background-color: #60b17f;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 0 24px rgba(0, 0, 0, .1);
    display: flex;
    justify-content: center;
    align-items: center;
    &:after {
        content: '';
        background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjMwIiB3aWR0aD0iMzUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxkZWZzPjxmaWx0ZXIgaWQ9ImEiIGhlaWdodD0iMTM4LjclIiB3aWR0aD0iMTMxLjQlIiB4PSItMTUuNyUiIHk9Ii0xNS4xJSI+PGZlTW9ycGhvbG9neSBpbj0iU291cmNlQWxwaGEiIG9wZXJhdG9yPSJkaWxhdGUiIHJhZGl1cz0iMSIgcmVzdWx0PSJzaGFkb3dTcHJlYWRPdXRlcjEiLz48ZmVPZmZzZXQgZHk9IjEiIGluPSJzaGFkb3dTcHJlYWRPdXRlcjEiIHJlc3VsdD0ic2hhZG93T2Zmc2V0T3V0ZXIxIi8+PGZlR2F1c3NpYW5CbHVyIGluPSJzaGFkb3dPZmZzZXRPdXRlcjEiIHJlc3VsdD0ic2hhZG93Qmx1ck91dGVyMSIgc3RkRGV2aWF0aW9uPSIxIi8+PGZlQ29tcG9zaXRlIGluPSJzaGFkb3dCbHVyT3V0ZXIxIiBpbjI9IlNvdXJjZUFscGhhIiBvcGVyYXRvcj0ib3V0IiByZXN1bHQ9InNoYWRvd0JsdXJPdXRlcjEiLz48ZmVDb2xvck1hdHJpeCBpbj0ic2hhZG93Qmx1ck91dGVyMSIgdmFsdWVzPSIwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwLjA3IDAiLz48L2ZpbHRlcj48cGF0aCBpZD0iYiIgZD0iTTE0LjIzIDIwLjQ2bC05LjY1IDEuMUwzIDUuMTIgMzAuMDcgMmwxLjU4IDE2LjQ2LTkuMzcgMS4wNy0zLjUgNS43Mi00LjU1LTQuOHoiLz48L2RlZnM+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48dXNlIGZpbGw9IiMwMDAiIGZpbHRlcj0idXJsKCNhKSIgeGxpbms6aHJlZj0iI2IiLz48dXNlIGZpbGw9IiNmZmYiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIyIiB4bGluazpocmVmPSIjYiIvPjwvZz48L3N2Zz4=) !important;
        background-size: contain !important;
        background-repeat: no-repeat !important;
        background-position: center !important;
        display: inline-block !important;
        width: 33px !important;
        height: 28px !important;
        top: 18px !important;
        left: 13px !important;
    }
}

.contactBtnUp {
    bottom: 54px + 16px;
}