@import '/styles/vars';

.icon {
    user-select: none;
    width: $gap24;
    height: $gap24;
    display: inline-block;
    position: relative;
    vertical-align: top;
    flex-shrink: 0;
    transform: translate3d(0, 0, 0);
    border: 0;
    padding: 0;
    font-size: $font18;
    text-align: center;
    line-height: $gap24;
    background: transparent;
    font-weight: $medium;
    color: $color_green_dark;
    transition: color $ease, background $ease;
}

.image {
    width: $gap24;
    height: $gap24;
    fill: none;
    padding: 1px;
    pointer-events: none;
    vertical-align: top;
}

.action {
    cursor: pointer;
    &:hover {
        color: $color_accent;
        .dot {
            fill: $color_accent;
        }
    }
}

.btn {
    width: $gap48;
    height: $gap48;
    border-radius: 24px;
    text-align: center;
    padding: $gap12;
    cursor: pointer;
    &:hover {
        color: $color_dark;
    }
    &.yellow {
        color: $color_dark;
        background: $color_accent;
        &:hover {
            background: $hover_color_accent;
        }
    }
    &.light {
        background: $color_light;
        &:hover {
            background: $hover_color_light;
        }
    }
    //@include mobile {
    //width: $gap42;
    //height: $gap42;
    //padding: 9px;
    //}
}

.inactive {
    pointer-events: none;
    opacity: 0.3;
}

@include mobile {
    .icon {
        transition: none !important;
        svg {
            padding: 3px;
        }
    }
}
