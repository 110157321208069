::selection {
    color: #ffffff;
    background: $color_accent;
}

div::-webkit-scrollbar {
    display: none !important;
}

#__next {
    width: 100%;
    overflow: hidden;
}

:root {
    --vw100: 100vw;
}

*,
*::before,
*::after {
    box-sizing: border-box;
    outline: none;
    margin: 0;
}

* {
    touch-action: manipulation;
}

h1,
h2,
h3 {
    font-weight: $bold;
}

b {
    font-weight: $medium;
}

html {
    background: $color_bg;
}

//#__next {
//    width: 100%;
//    overflow: hidden;
//}

html,
body {
    padding: 0;
    margin: 0;
    font-size: $font16;
    line-height: 1.5;
    color: $color_medium;
    font-weight: $normal;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html,
body,
input,
select,
textarea {
    font-family: $bodyFont;
}

body {
    @include mobile {
        font-size: $fontm;
    }
}

small {
    font-size: $font14;
}

svg {
    stroke-width: 1.6;
}

// * {
//     font-family: $bodyFont;
//     // font-family: Inter-Regular, 'system-ui', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue,
//     //     Helvetica, Arial, 'sans-serif';
// }

a {
    cursor: pointer;
    color: inherit;
    text-decoration: none;
    transition: color $ease;
    &:hover {
        text-decoration: underline;
    }
}

.noScroll {
    position: relative;
    overflow: hidden;
}

b {
    color: $color_dark;
}

@include mobile {
    .ais-Stats,
    .ais-Meta select,
    input,
    select:focus,
    textarea {
        font-size: 16px !important;
    }
    .tableContainer {
        overflow: auto;
        width: auto;
        padding: 0 12px;
        margin: 0 -12px;
        table {
            width: 1000px !important;
        }
    }
}

.fb_dialog_content {
    iframe {
        box-shadow: none !important;
        right: 0 !important;
        transform: scale(0.8) !important;
        transform-origin: bottom right !important;
        margin: 12px !important;
        bottom: 0 !important;
    }
}


// [aria-label="Kalėdos"] {
//     display: none !important;
// }